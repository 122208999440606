// Members.jsx
import React from 'react';
import './Members.css'; // Import your CSS file for styling
import { FaPhone } from "react-icons/fa6";

const membersData = [
  { name: 'DR R K BAKSHI', designation: 'PRESIDENT', address: 'A-189, Shivalik', contact: '98111-12006', image: 'member1.jpg' },
  { name: 'SH M K KANSAL', designation: 'VICE-PRESIDENT', address: 'A-186, Shivalik', contact: '98916-79324', image: 'member2.jpg' },
  { name: 'SH J B MUDGAL', designation: 'VICE-PRESIDENT', address: 'B-21, Shivalik', contact: '99105-14996', image: 'member3.jpg' },
  { name: 'SH RAJEEV JAIN', designation: 'SECRETARY', address: 'A-250(GF) Shivalik', contact: '98993-85884', image: 'member10.jpg' },
  { name: 'SH P C SOOD', designation: 'TREASURER', address: 'A-74, Shivalik', contact: '98184-43754', image: 'member8.jpg' },
  { name: 'MS REKHA MANOCHA', designation: 'LADY-MEMBER', address: 'B-60, Shivalik', contact: '99104-00226', image: 'member5.jpg' },
  { name: 'SH P K KHANNA', designation: 'MEMBER', address: 'C-106(GF) Shivalik', contact: '87007-45866', image: 'member6.jpg' },
  { name: 'SH AJAY VASHISHT', designation: 'MEMBER', address: 'C-81, Shivalik', contact: '79825-96957', image: 'AjayVashisht.jpeg' },
  { name: 'SH ATUL KAPUR', designation: 'MEMBER', address: 'A-92, Shivalik', contact: '98112-27744', image: 'AtulKapur.jpeg' },
  { name: 'SH SURENDER KAUL', designation: 'MEMBER', address: 'B-156, Shivalik', contact: '99999-55984', image: 'Surender.jpeg' },
  
  // { name: 'SH ABINASH SHARMA', designation: 'MEMBER', address: 'C-56(FF) Shivalik', contact: '88105-56335 / 98100-75715', image: 'member7.jpg' },
  // { name: 'SH AJAY VASHISHT', designation: 'MEMBER', address: 'C-81(GF) Shivalik', contact: '98911-37576 / 79825-96957', image: 'member9.jpg' },
  // { name: 'SH D R SACHDEVA', designation: 'VICE-PRESIDENT', address: 'B-165, Shivalik', contact: '4058-2764 / 93137-00920', image: 'member3.jpg' },
  // { name: 'SMT C P GULATI', designation: 'TREASURER', address: 'A-206(FF) Shivalik', contact: '49785606 / 98715-14781', image: 'member4.jpg' },

];

const Members = () => {
  return (
    <div className="members-container">
      <div className="topbar_member">
      <h1>Members</h1>

      </div>
      <div className="members-list fadeIn">
        {membersData.map((member, index) => (
          <div key={index} className="member-card ">
            <img src={`/images/${member.image}`} alt={`${member.name}`}/>
            <div className="member-details">
              <h2>{member.name}</h2>
              <p>{member.designation}</p>
              <p>{member.address}</p>
              <p className='phone'><FaPhone/> {member.contact}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Members;
